import React, { useEffect } from "react";
import { Layout, SEO, Image, HeroBanner } from "../../components"
import {
	validateArray,
	validateImageCT,
	validateRTE,
} from "../../components/utilities/functions"
import "./index.scss"

const Careers = props => {
	const { pageContext } = props
	const { jobs, departments } = pageContext
	const {
		title,
		hero_section,
		url,
		seo = {},
		content,
		images_grid,
		jobs_title,
		uid = "",
		locale = "",
	} = pageContext.node || {}
	const job_details_page_url = "/careers/jobs"
	seo.title = (seo && seo.title) || title

	const extension = {
		contenttype: pageContext?.contentType || "",
		uid: uid,
		locale: locale,
	}

	useEffect(() => {
		const handleMessage = event => {
			if (event.data.type === "iframeHeight") {
				console.log({ event })
				const iframe = document.getElementById("dynamic-iframe")
				iframe.style.height = `${event.data.height}px`
			}
		}

		window.addEventListener("message", handleMessage, false)

		return () => {
			window.removeEventListener("message", handleMessage)
		}
	}, [])

	return (
		<Layout className="careers-wrapper" pageURL={url}>
			<SEO pageURL={url} {...seo} extension={extension} />

			{hero_section && (
				<HeroBanner
					image={hero_section.image}
					imageAlt={hero_section.image_alt}
					heading={hero_section.heading}
					hasOverlayBG={true}
				/>
			)}

			<div className="container box-10080">
				{content && (content.heading || content.description) && (
					<div className="leadership-section text-center">
						<div className="col-12 col-768-9 mx-auto">
							{content.heading && (
								<h2 className="title-lineover">{content.heading}</h2>
							)}
							{validateRTE(content.description) && (
								<div
									className="p__body1"
									dangerouslySetInnerHTML={{ __html: content.description }}
								/>
							)}
						</div>
					</div>
				)}

				{/* //Image grid */}
				{images_grid &&
					(images_grid.first_grid ||
						validateArray(images_grid.second_images_grid)) && (
						<div className="image-grid">
							{validateImageCT(images_grid.first_grid) && (
								<div className="img-grid-item grid-img1">
									<Image data={images_grid.first_grid} />
								</div>
							)}
							{validateArray(images_grid.second_third_grid) &&
								images_grid.second_third_grid.map((item, index) => (
									<div
										className={`img-grid-item  grid-img-2${index + 1} ${
											index === 0 || index === 1 ? "grid-img2" : "grid-img3"
										}`}
										key={index}
									>
										<Image data={item} />
									</div>
								))}
						</div>
					)}
				<div className="jobs-frame">
					<h2 className="title-lineover">{jobs_title || "Browse Jobs"}</h2>

					<iframe
						id="kekaCareer"
						src="https://surfboard.keka.com/careers/api/embedjobs/8a4656d6-c58d-4e60-86e2-5d09bc90b227"
						width="100%"
					/>
				</div>
			</div>
		</Layout>
	)
}

export default Careers
